// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const BO_ORDER_PRODUCT_AMBASSADOR = "Ambas\u00e1dorka";
export const BO_ORDER_PRODUCT_BILLBOARD = "Billboard";
export const BO_ORDER_PRODUCT_BRANDING = "Branding";
export const BO_ORDER_PRODUCT_CONTEST = "S\u00fa\u0165a\u017e";
export const BO_ORDER_PRODUCT_COUNSELING = "Porad\u0148a";
export const BO_ORDER_PRODUCT_DAYS_WITH = "Dni so zna\u010dkou";
export const BO_ORDER_PRODUCT_HALFPAGE = "Halfpage";
export const BO_ORDER_PRODUCT_HALFPAGE_VIDEO = "Video v Halfpage";
export const BO_ORDER_PRODUCT_IP = "Spr\u00e1va do IP";
export const BO_ORDER_PRODUCT_IP_REMARKETING = "Spr\u00e1va do IP s remarketingom";
export const BO_ORDER_PRODUCT_LARGE_RECTANGLE = "Large Rectangle";
export const BO_ORDER_PRODUCT_LARGE_RECTANGLE_VIDEO = "Video v Large Rectangle";
export const BO_ORDER_PRODUCT_NATIVE_ARTICLE = "Nat\u00edvny \u010dl\u00e1nok";
export const BO_ORDER_PRODUCT_NATIVE_CONTENT_AD = "Native Content Ad";
export const BO_ORDER_PRODUCT_OUTSTREAM_VIDEO = "Outstream video";
export const BO_ORDER_PRODUCT_PR_ARTICLE = "PR \u010dl\u00e1nok";
export const BO_ORDER_PRODUCT_PRO_ESHOP = "Roz\u0161\u00edrenie PRO pre eshop";
export const BO_ORDER_PRODUCT_REDACTION_ARTICLE = "Redak\u010dn\u00fd \u010dl\u00e1nok";
export const BO_ORDER_PRODUCT_RESEARCH = "Prieskum";
export const BO_ORDER_PRODUCT_REVIEWS_DETAILED_REVIEWS = "Centrum recenzi\u00ed: Pou\u017e\u00edvate\u013esk\u00e9 testovanie";
export const BO_ORDER_PRODUCT_REVIEWS_PAGE = "Centrum recenzi\u00ed: Produktov\u00e1 str\u00e1nka";
export const BO_ORDER_PRODUCT_REVIEWS_PROMO = "Centrum recenzi\u00ed: Propag\u00e1cia benefitov";
export const BO_ORDER_PRODUCT_REVIEWS_PROMO_EXTRA = "Centrum recenzi\u00ed: Propag\u00e1cia benefitov - plus kreat\u00edva";
export const BO_ORDER_PRODUCT_REVIEWS_REDACTION_TESTING = "Centrum recenzi\u00ed: Redak\u010dn\u00e9 testovanie*";
export const BO_ORDER_PRODUCT_REVIEWS_REDACTION_TESTING_WITH_EXPS = "Centrum recenzi\u00ed: Redak\u010dn\u00e9 testovanie";
export const BO_ORDER_PRODUCT_REVIEWS_SAMPLING = "Sampling: Rozoslanie produktov na z\u00edskavanie recenzi\u00ed do Centra recenzi\u00ed";
export const BO_ORDER_PRODUCT_REVIEWS_YOUTUBE = "Centrum recenzi\u00ed: YouTube predstavenie";
export const BO_ORDER_PRODUCT_SEO_ARTICLE = "SEO \u010dl\u00e1nok";
export const BO_ORDER_PRODUCT_TESTING = "Sk\u00fasenosti so zna\u010dkou";
export const BO_ORDER_PRODUCT_UNKNOWN = "In\u00e9";
export const BO_ORDER_PRODUCT_YOUTUBE = "YouTube video";
export const BO_ORDERS_INVOICING = "Faktur\u00e1cia";
export const DASH_ORDERS = "Objedn\u00e1vky";
export const DASH_ORDERS_CHART = "Graf";
export const DASH_ORDERS_SIGNED = ["Podp\u00edsan\u00e1", "Podp\u00edsan\u00e9", ""];
export const DASH_ORDERS_STATS = "\u0160tatistiky";
